import React from "react"
import { createTheme, ThemeProvider, CssBaseline, makeStyles } from '@material-ui/core'
import "@fontsource/lora"
import "@fontsource/outfit"

const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      margin:"0px",
      backgroundColor: "#fffff"
    }
  }
});
const theme = createTheme({
  typography: {
    fontFamily: [
      'Outfit',
      'KnowledgeLight',
      'Raleway',
      'Helvetica',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    h2: {
      letterSpacing:"-1px",
      fontSize:"2.6rem",
      color: "#fff",
      '@media (min-width:780px)': {
        fontSize:"2.8rem",
      }
    },
    body1:{
      fontFamily: [
        'Outfit',
        'Raleway',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: "0.9rem",
      lineHeight:"1rem",
      fontWeight: 300,
      color: "#3b3b3b",
      marginBottom: "18px",
      textShadow: "none",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        lineHeight:"1.2rem",
      },
    },
    body2:{
      fontFamily: [
        'Outfit',
        'Raleway',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: "0.9rem",
      lineHeight:"0.9rem",
      fontWeight: 300,
      letterSpacing:"0px",
      marginBottom: "18px",
      color: "#d1d1d1",
      textShadow: "none",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        lineHeight:"1.2rem",
      },
    },
    button: {
      marginTop: "24px",
      backgroundColor: "#2c362a",
      color: "#fff",
      fontWeight: 500
    }
  },
  palette: {
    primary: {
      main: '#ccc'
    },
  },
  overrides: {

  }

});

function MyThemeProvider({ children }) {
  useGlobalStyles();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

const Theme = ({ path, children }) => {
  return (
    <MyThemeProvider>
      <CssBaseline />
      {children}
    </MyThemeProvider>
  )
}
export default Theme